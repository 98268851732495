.navbar {
  position: relative;
  img {
    position: absolute;
    width: 162px;
    height: 30px;
    margin-left: 164px;
    top: 54px;
  }
}

// MacBook Pro 16
@media (max-width: 1728px) {
  .navbar {
    img {
      margin-left: 115px;
      top: 47.6px;
    }
  }
}

// MacBook Pro 14
@media (max-width: 1512px) {
  .navbar {
    img {
      top: 46.41px;
    }
  }
}

// Desktop
@media (max-width: 1440px) {
  .navbar {
    img {
      margin-left: 146.5px;
      top: 46px;
    }
  }
}

// Macbook Air
@media (max-width: 1280px) {
  .navbar {
    img {
      margin-left: 115px;
      top: 46.34px;
    }
  }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
  .navbar {
    img {
      margin-left: 112px;
      width: 149px;
      height: 28px;
      top: 46px;
    }
  }
}

// Iphone 14 Pro Max
@media (max-width: 750px) {
  .navbar {
    img {
      margin-left: 53px;
      width: 143px;
      height: 27px;
      top: 30px;
    }
  }
}

// Iphone 14
@media (max-width: 390px) {
  .navbar {
    img {
      margin-left: 34px;
    }
  }
}

// Android Large
@media (max-width: 360px) {
  .navbar {
    img {
      margin-left: 18px;
    }
  }
}
