.merchandise {
    background-color: black;
    color: white;
    .merchandise-examples {
        .merchandise-pictures {
            text-align: center;
            .merchandise-img {
                width: 990.65px;
                height: 661px;
            }
        }
    }
}

// MacBook Pro 16
@media (max-width: 1728px) {
    .merchandise {
        .merchandise-examples {
            .merchandise-pictures {
                .merchandise-img {
                    width: 1146.38px;
                    height: 764.9px;
                }
            }
        }
    }
}

// MacBook Pro 14
@media (max-width: 1512px) {
    .merchandise {
        .merchandise-examples {
            .merchandise-pictures {
                .merchandise-img {
                    width: 1041.38px;
                    height: 694.84px;
                }
            }
        }
    }
}

// Desktop
@media (max-width: 1440px) {
    .merchandise {
        .merchandise-examples {
            .merchandise-pictures {
                .merchandise-img {
                    width: 878.38px;
                    height: 586.08px;
                }
            }
        }
    }    
}

// MacBook Pro air
@media (max-width: 1280px) {
    .merchandise {
        .merchandise-examples {
            .merchandise-pictures {
                .merchandise-img {
                    width: 886.38px;
                    height: 591.42px;
                }
            }
        }
    }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
    .merchandise {
        .merchandise-examples {
            .merchandise-pictures {
                .merchandise-img {
                    width: 763px;
                    height: 509.1px;
                }
            }
        }
    }
}

// IPad Pro 11
@media (max-width: 834px) {
    .merchandise {
        .merchandise-examples {
            .merchandise-pictures {
                .merchandise-img {
                    width: 659.44px;
                    height: 440px;
                }
            }
        }
    }
}

// Iphone 14 Pro Max
@media (max-width: 700px) {
    .merchandise {
        .merchandise-examples {
            .merchandise-pictures {
                .merchandise-img {
                    width: 304.24px;
                    height: 203px;
                }
            }
        }
    }
}