.clients {
  display: flex;
  overflow: hidden;
  height: 388px;
  align-items: center;
  padding: 0 150px;
  .client {
    img {
      margin: 0 115px;
      height: 113px;
    }
  }
}

// MacBook Pro 16
@media (max-width: 1728px) {
  .clients {
    height: 353px;
    padding: 0 80px;
  }
}

// MacBook Pro 14
@media (max-width: 1512px) {
  .clients {
    height: 302px;
    padding: 0 100px;
  }
}

// Desktop
@media (max-width: 1440px) {
  .clients {
    height: 292px;
    padding: 0 20px;
    .client {
      img {
        height: 100px;
      }
    }
  }
}

// MacBook Pro air
@media (max-width: 1280px) {
  .clients {
    height: 260px;
    padding: 0 70px;
  }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
  .clients {
    height: 243px;
    padding: 0 40px;
    .client {
      img {
        height: 80px;
        margin: 0 70px;
      }
    }
  }
}

// IPad
@media (max-width: 834px) {
  .clients {
    padding: 0;
  }
}

@media (max-width: 430px) {
  .clients {
    height: 155px;
    padding: 0;
    .client {
      img {
        height: 65px;
        margin: 0 50px;
      }
    }
  }
}
