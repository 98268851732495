.website {
  background-color: black;
  color: white;
}

.website-post {
  width: 989px;
  padding-bottom: 5rem;
  text-align: center;
}
.website-pictures {
  text-align: center;
}
.website-story {
  width: 990px;
  height: 2078.48px;
}
.website-one-pictures {
  text-align: center;
}
.website-button {
  text-align: center;
  margin-top: 185px;
  padding-bottom: 80px;
}

.website-pictures .website-hasbara {
  text-align: center;
  width: 990px;
  height: 2078.48px;

  padding-bottom: 5rem;
  padding-top: 5rem;
}

.website-two-pictures {
  display: flex;
  gap: 90px;
  justify-content: center;
}
.website-page1 {
  width: 390px;
  height: 844px;
}
.website-page2 {
  width: 307.576px;
  height: 1168px;
}

/* MacBook Pro 16 */
@media (max-width: 1728px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 819px;
  }
  .website-pictures .website-hasbara {
    width: 818.39px;
    height: 1800.19px;
    padding-top: 0rem;
  }
}

/* MacBook Pro 14 */
@media (max-width: 1512px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 683px;
  }
  .website-pictures .website-hasbara {
    width: 681.99px;
    height: 1431.83px;
    padding-top: 0rem;
  }
  .website-two-pictures {
    gap: 76.38px;
  }
  .website-page1 {
    width: 314.68px;
    height: 681px;
  }
  .website-page2 {
    width: 291.512px;
    height: 1107px;
  }
}

/* Desktop */
@media (max-width: 1440px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 684px;
  }
  .website-pictures .website-hasbara {
    width: 681.99px;
    height: 1431.83px;
    padding-top: 0rem;
  }
  .website-page1 {
    width: 390px;
    height: 844px;
  }
  .website-page2 {
    width: 307.576px;
    height: 1168px;
  }
}

/* MacBook Pro air */
@media (max-width: 1280px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 681.614px;
  }
  .website-pictures .website-hasbara {
    width: 681.99px;
    height: 1431.83px;
    padding-top: 0rem;
  }
  .website-two-pictures {
    gap: 79.94px;
  }
  .website-page1 {
    width: 310.059px;
    height: 671px;
  }
  .website-page2 {
    width: 291.512px;
    height: 1107px;
  }
}

/* IPad Pro 12.9 */
@media (max-width: 1024px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 532.698px;
  }
  .website-pictures .website-hasbara {
    width: 533.52px;
    height: 1120.11px;
    padding-top: 0rem;
  }
  .website-two-pictures {
    gap: 53.73px;
  }
  .website-page1 {
    width: 239.272px;
    height: 517.808px;
  }
  .website-page2 {
    width: 240.002px;
    height: 911.393px;
  }
}

/* IPad Pro 11 */
@media (max-width: 834px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 533px;
  }
  .website-pictures .website-hasbara {
    width: 533.52px;
    height: 1120.11px;
    padding-top: 0rem;
  }
  .website-two-pictures {
    gap: 56.73px;
  }
  .website-page1 {
    width: 239.272px;
    height: 517.808px;
  }
  .website-page2 {
    width: 237.002px;
    height: 900px;
  }
}

/* Iphone 14 Pro Max */
@media (max-width: 700px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 303.83px;
  }
  .website-pictures .website-hasbara {
    width: 305px;
    height: 750.34px;
    padding-top: 0rem;
  }
  .website-two-pictures {
    flex-direction: column;
    gap: 49.16;
    text-align: center;
  }
  .website-page1 {
    width: 239.272px;
    height: 517.808px;
  }
  .website-page2 {
    width: 239px;
    height: 907.587px;
  }
}

/* Iphone 14 */
@media (max-width: 390px) {
  .website {
    width: 100%;
  }

  .website-post {
    width: 303.83px;
  }
  .website-pictures .website-hasbara {
    width: 305px;
    height: 750.34px;
    padding-top: 0rem;
  }
}

/* Android Large */
@media (max-width: 360px) {
  .website {
    width: 125%;
  }

  .website-post {
    width: 303.83px;
  }
  .website-pictures .website-hasbara {
    width: 305px;
    height: 750.34px;
    padding-top: 0rem;
  }
}
