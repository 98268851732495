.signs {
    background-color: black;
    color: white;
    .signs-examples {
        .signs-pictures {
            text-align: center;
            .signs-img {
                width: 991px;
                height: 660.75px;
            }
        }
    }
}

// MacBook Pro 16
@media (max-width: 1728px) {
    .signs {
        .signs-examples {
            .signs-pictures {
                .signs-img {
                    width: 1146.38px;
                    height: 764.35px;
                }
            }
        }
    }
}

// MacBook Pro 14
@media (max-width: 1512px) {
    .signs {
        .signs-examples {
            .signs-pictures {
                .signs-img {
                    width: 1040.87px;
                    height: 694px;
                }
            }
        }
    }
}

// Desktop
@media (max-width: 1440px) {
    .signs {
        .signs-examples {
            .signs-pictures {
                .signs-img {
                    width: 877.39px;
                    height: 585px;
                }
            }
        }
    }
}

// MacBook Pro air
@media (max-width: 1280px) {
    .signs {
        .signs-examples {
            .signs-pictures {
                .signs-img {
                    width: 886.39px;
                    height: 591px;
                }
            }
        }
    }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
    .signs {
        .signs-examples {
            .signs-pictures {
                .signs-img {
                    width: 763px;
                    height: 508.73px;
                }
            }
        }
    }
}

// IPad Pro 11
@media (max-width: 834px) {
    .signs {
        .signs-examples {
            .signs-pictures {
                .signs-img {
                    width: 660px;
                    height: 440.05px;
                }
            }
        }
    }
}

// Iphone 14 Pro Max
@media (max-width: 700px) {
    .signs {
        .signs-examples {
            .signs-pictures {
                .signs-img {
                    width: 305px;
                    height: 203.36px;
                }
            }
        }
    }
}