.rebranding {
  display: flex;
  margin: 100px 0;
  .rebranding-photo {
    margin-left: 274px;
    img {
      width: 729px;
    }
  }
  .rebranding-info {
    margin-left: 79.49px;
    margin-top: 30px;
    .rebranding-header {
      margin-bottom: 40px;
      h1 {
        font-family: "Roc Grotesk 400";
        font-style: normal;
        font-size: 32px;
        line-height: 70px;
        color: #2c400e;
      }
    }
    .rebranding-text {
      font-family: "Roc Grotesk 300";
      font-style: normal;
      font-size: 16px;
      color: #000000;
      .rebranding-newLine {
        margin-top: 25px;
      }
    }
    .rebranding-text-mobile {
      display: none;
      margin: auto;
      font-family: "Roc Grotesk 300";
      font-style: normal;
      font-size: 16px;
      color: #000000;
      .rebranding-newLine {
        margin-top: 25px;
      }
    }
  }
}

// MacBook Pro 16
@media (max-width: 1728px) {
  .rebranding {
    margin-top: 91.79px;
    margin-bottom: 90px;
    .rebranding-photo {
      margin-left: 187px;
      img {
        width: 640.712px;
      }
    }
    .rebranding-info {
      margin-left: 43.38px;
      margin-top: 25px;
      .rebranding-header {
        // margin-bottom: 40px;
        h1 {
          font-size: 36px;
          line-height: 63px;
        }
      }
      .rebranding-text {
        font-size: 16px;
        line-height: 24px;
        .rebranding-newLine {
          // margin-top: 25px;
        }
      }
    }
  }
}

// MacBook Pro 14
@media (max-width: 1512px) {
  .rebranding {
    margin-top: 80.5px;
    margin-bottom: 78.78px;
    .rebranding-photo {
      margin-left: 152.16px;
      img {
        width: 560.475px;
      }
    }
    .rebranding-info {
      margin-left: 76.75px;
      margin-top: 20px;
      .rebranding-header {
        margin-bottom: 32px;
        h1 {
          font-size: 32px;
          line-height: 55px;
        }
      }
      .rebranding-text {
        font-size: 16px;
        line-height: 24px;
        .rebranding-newLine {
          margin-top: 21px;
        }
      }
    }
  }
}

// Desktop
@media (max-width: 1440px) {
  .rebranding {
    margin-top: 79.24px;
    margin-bottom: 73.15px;
    .rebranding-photo {
      margin-left: 144.46px;
      img {
        width: 532.933px;
      }
    }
    .rebranding-info {
      margin-left: 54.11px;
      margin-top: 22px;
      .rebranding-header {
        margin-bottom: 31px;
        h1 {
          font-size: 30px;
          line-height: 52px;
        }
      }
      .rebranding-text {
        font-size: 15px;
        line-height: 22px;
        .rebranding-newLine {
          margin-top: 21px;
        }
      }
    }
  }
}

// MacBook Pro air
@media (max-width: 1280px) {
  .rebranding {
    margin-top: 68.18px;
    margin-bottom: 63px;
    .rebranding-photo {
      margin-left: 120px;
      img {
        width: 486.197px;
      }
    }
    .rebranding-info {
      margin-left: 35.21px;
      margin-top: 18px;
      .rebranding-header {
        margin-bottom: 27px;
        h1 {
          font-size: 28px;
          line-height: 47px;
        }
      }
      .rebranding-text {
        width: 90%;
        font-size: 16px;
        line-height: 24px;
        .rebranding-newLine {
          margin-top: 25px;
        }
      }
    }
  }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
  .rebranding {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    .rebranding-photo {
      display: none;
    }
    .rebranding-info {
      text-align: center;
      margin-left: 0;
      margin-top: 76px;
      margin-bottom: 75px;
      .rebranding-header {
        margin-bottom: 30px;
        h1 {
          margin: 0;
          font-size: 30px;
          line-height: 67px;
        }
      }
      .rebranding-text {
        width: 100%;
        font-size: 15px;
        line-height: 25px;
        .rebranding-newLine {
          margin-top: 25px;
        }
      }
    }
  }
}

// IPad Pro 11
@media (max-width: 834px) {
  .rebranding {
    .rebranding-info {
      margin-bottom: 100px;
    }
  }
}

// Iphone 14 Pro Max
@media (max-width: 530px) {
  .rebranding {
    .rebranding-info {
      text-align: center;
      width: 84%;
      margin: auto;
      margin-top: 55px;
      margin-bottom: 55px;
      .rebranding-header {
        margin-bottom: 22px;
        h1 {
          font-size: 22px;
          line-height: 36px; /* 163.636% */
        }
      }
      .rebranding-text {
        display: none;
      }
      .rebranding-text-mobile {
        width: 89%;
        display: block;
        font-size: 15px;
        line-height: 21px;
        .rebranding-newLine {
          margin-top: 22px;
        }
      }
    }
  }
}

// Iphone 14
@media (max-width: 390px) {
  .rebranding {
    .rebranding-info {
      .rebranding-text-mobile {
        width: 95%;
      }
    }
  }
}

// Android Large
@media (max-width: 360px) {
  .rebranding {
    .rebranding-info {
      width: 90%;
      .rebranding-text-mobile {
        width: 100%;
      }
    }
  }
}
