.header {
  height: 855.05px;
  width: 100%;
  background-color: #000000;
  color: white;

  .header-container {
    padding-top: 281px;
    display: flex;
    justify-content: center;
    position: relative;

    .header-info {
      text-align: center;
      .header-text {
        font-family: "Roc Grotesk 800";
        color: #fff;
        font-size: 100px;
        font-style: normal;
        line-height: 105px;
        letter-spacing: 3px;
        .header-first-text {
          position: relative;
          width: fit-content;
          margin: auto;
          .header-photo {
            position: absolute;
            top: -90px;
            right: -125px;
            height: 162px;
            width: 162px;
            img {
              height: 162px;
              width: 162px;
            }
          }
        }
      }
      .header-button {
        margin-top: 66px;
      }
    }
    .header-photo-mobile {
      display: none;
    }
  }
}

// MacBook Pro 16
@media (max-width: 1728px) {
  .header {
    height: 770px;

    .header-container {
      padding-top: 215.39px;

      .header-info {
        .header-text {
          font-size: 109px;
          line-height: 115px; /* 105.505% */
          letter-spacing: 3.27px;
          .header-first-text {
            .header-photo {
              top: -70px;
              right: -110px;
              width: 150px;
              height: 150px;
              img {
                width: 150px;
                height: 150px;
              }
            }
          }
        }
        .header-button {
          margin-top: 72.38px;
        }
      }
    }
  }
}

// MacBook Pro 14
@media (max-width: 1512px) {
  .header {
    height: 672.96px;

    .header-container {
      padding-top: 197.88px;

      .header-info {
        .header-text {
          font-size: 96.122px;
          line-height: 100.928px; /* 105% */
          letter-spacing: 2.884px;
          .header-first-text {
            .header-photo {
              top: -80px;
              right: -115px;
              width: 150px;
              height: 150px;
              img {
                width: 150px;
                height: 150px;
              }
            }
          }
        }
        .header-button {
          margin-top: 57px;
        }
      }
    }
  }
}

// Desktop
@media (max-width: 1440px) {
  .header {
    height: 640px;

    .header-container {
      padding-top: 189.31px;

      .header-info {
        .header-text {
          font-size: 90px;
          line-height: 96.122px; /* 106.802% */
          letter-spacing: 2.7px;
        }
        .header-button {
          margin-top: 54.23px;
        }
      }
    }
  }
}

// MacBook Pro air
@media (max-width: 1280px) {
  .header {
    height: 570px;

    .header-container {
      padding-top: 168.39px;

      .header-info {
        .header-text {
          font-size: 81.373px;
          line-height: 85.442px; /* 105% */
          letter-spacing: 2.441px;
          .header-first-text {
            .header-photo {
              top: -75px;
              right: -100px;
              width: 132px;
              height: 132px;
              img {
                width: 132px;
                height: 132px;
              }
            }
          }
        }
        .header-button {
          margin-top: 48.2px;
        }
      }
    }
  }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
  .header {
    height: 648px;

    .header-container {
      padding-top: 203px;

      .header-info {
        .header-text {
          font-size: 71px;
          line-height: 75.115px; /* 105.796% */
          letter-spacing: 2.13px;
          .header-first-text {
            .header-photo {
              top: -65px;
              right: -90px;
              width: 116px;
              height: 116px;
              img {
                width: 116px;
                height: 116px;
              }
            }
          }
        }
        .header-button {
          margin-top: 42.37px;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .header {
    .header-container {
      .header-photo {
        right: 225px;
      }
    }
  }
}

// IPad Pro 11
@media (max-width: 834px) {
  .header {
    height: 817px;
    .header-container {
      padding-top: 240.8px;

      .header-info {
        .header-text {
          font-size: 78px;
          line-height: 78.797px; /* 101.021% */
          letter-spacing: 2.34px;
          .header-first-text {
            .header-photo {
              top: -95px;
              right: -110x;
              width: 148px;
              height: 148px;
              img {
                width: 148px;
                height: 148px;
              }
            }
          }
        }
        .header-button {
          margin-top: 79px;
        }
      }
    }
  }
}

// Iphone 14 Pro Max
@media (max-width: 750px) {
  .header {
    height: 548px;

    .header-container {
      padding-top: 171px;
      .header-info {
        .header-text {
          font-size: 45px;
          line-height: 50px; /* 111.111% */
          letter-spacing: 1.35px;
          .header-first-text {
            .header-photo {
              display: none;
            }
          }
        }
        .header-button {
          margin-top: 26px;
        }
      }
      .header-photo-mobile {
        display: block;
        position: absolute;
        top: 461px;
        right: 33px;
        img {
          width: 130px;
          height: 130px;
        }
      }
    }
  }
}

// Iphone 14
@media (max-width: 390px) {
  .header {
    .header-container {
      .header-photo-mobile {
        right: 34px;
      }
    }
  }
}

// Android Large
@media (max-width: 360px) {
  .header {
    .header-container {
      .header-photo-mobile {
        right: 22px;
      }
    }
  }
}
