* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Poller One";
  src: url("../public/Fonts/Poller/PollerOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roc Grotesk 800";
  src: url("../public/Fonts/Roc_Grotesk_Fonts/Kostic\ -\ Roc\ Grotesk\ ExtraBold.otf")
    format("truetype");
}
@font-face {
  font-family: "Roc Grotesk 500";
  src: url("../public/Fonts/Roc_Grotesk_Fonts/Kostic\ -\ Roc\ Grotesk\ Medium.otf")
    format("truetype");
}
@font-face {
  font-family: "Roc Grotesk 400";
  src: url("../public/Fonts/Roc_Grotesk_Fonts/Kostic\ -\ Roc\ Grotesk\ Regular.otf")
    format("truetype");
}
@font-face {
  font-family: "Roc Grotesk 300";
  src: url("../public/Fonts/Roc_Grotesk_Fonts/Kostic\ -\ Roc\ Grotesk\ Light.otf")
    format("truetype");
}
@font-face {
  font-family: "Roc Grotesk 100";
  src: url("../public/Fonts/Roc_Grotesk_Fonts/Kostic\ -\ Roc\ Grotesk\ Thin.otf")
    format("truetype");
}

@font-face {
  font-family: "Ploni ML v2 AAA";
  src: url("../public/Fonts/Ploni-Light/ploni-aaa-300.otf") format("opentype");
}

@font-face {
  font-family: "Poppins Light";
  src: url("../public/Fonts/Poppins-Light/Poppins-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("../public/Fonts/Poppins-SemiBold/Poppins-SemiBold.ttf")
    format("opentype");
}

.img-1728 {
  display: none;
}
.img-1512 {
  display: none;
}
.img-1440 {
  display: none;
}
.img-1280 {
  display: none;
}
.img-1024 {
  display: none;
}
.img-834 {
  display: none;
}
.img-430 {
  display: none;
}
.img-390 {
  display: none;
}
.img-360 {
  display: none;
}

@media (max-width: 1728px) {
  .img-1920 {
    display: none;
  }
  .img-1728 {
    display: block;
  }
}

@media (max-width: 1512px) {
  .img-1728 {
    display: none;
  }
  .img-1512 {
    display: block;
  }
}

@media (max-width: 1440px) {
  .img-1512 {
    display: none;
  }
  .img-1440 {
    display: block;
  }
}

@media (max-width: 1280px) {
  .img-1440 {
    display: none;
  }
  .img-1280 {
    display: block;
  }
}

@media (max-width: 1024px) {
  .img-1280 {
    display: none;
  }
  .img-1024 {
    display: block;
  }
}

@media (max-width: 834px) {
  .img-1024 {
    display: none;
  }
  .img-834 {
    display: block;
  }
}

@media (max-width: 430px) {
  .img-834 {
    display: none;
  }
  .img-430 {
    display: block;
  }
}

@media (max-width: 390px) {
  .img-430 {
    display: none;
  }
  .img-390 {
    display: block;
  }
}

@media (max-width: 360px) {
  .img-390 {
    display: none;
  }
  .img-360 {
    display: block;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
